// @media screen and (max-width: $break-small) {
//     
// }

// Defining values
$small-range:   (0em, 40em);       /* 0, 640px */
$medium-range:  (40.063em, 64em);  /* 641px, 1024px */
$large-range:   (64.063em, 90em);  /* 1025px, 1440px */
$xlarge-range:  (90.063em, 120em); /* 1441px, 1920px */
$xxlarge-range: (120.063em);       /* 1921px */
$break-small: 641px;
$break-medium: 1024px;
$break-large: 1440px;
$break-xlarge: 1920px;

// Defining media queries
$screen:       "only screen" !default;
$landscape:    "#{$screen} and (orientation: landscape)" !default;
$portrait:     "#{$screen} and (orientation: portrait)" !default;
$small-up:     $screen !default;
$small-only:   "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;
$medium-up:    "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only:  "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
$large-up:     "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only:   "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
$xlarge-up:    "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only:  "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;
$xxlarge-up:   "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;


body {
    /* background: linear-gradient(to bottom, #ffffff 80%, #33cccc 100%); */
    /* background: #2F4F4F; */
    width: 100%;
    /* font-family: 'Lexend Exa'; */
    /* font-family: 'Titillium Web', sans-serif; */
    font-family: 'Montserrat', sans-serif;
    /* background-attachment: fixed; */
    max-width: 100%;
    overflow-x: hidden;
    // text-shadow: 1px 1px 1px rgba(0,0,0,.4);
    background-color: black;
    margin: 0;
}

#particles-js {
    position : fixed;
    width: 100vw !important;
    height: 100vh !important;
    z-index:-1;
}

/* .body-background{
    background: url(../images/glowing-background.jpg) no-repeat center center fixed;
    background-color: black;
    background-size: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;
    transform: translate3d(0px, 0px, 0px);
    transition: opacity .1s;
    position: absolute;
    z-index: 0;
} */

.outlet{
    display: inline-block;
    margin-right: 0px;
    z-index:0;
    // height: 10000px;
}


/*Navigation Bar Styling*/

    .nav-wrapper{
        width: 100%;
        overflow: hidden;
        pointer-events:none;
        margin: 0;
        position: fixed;
        height: 200px;
        /* border-bottom-color: white; */
        z-index: 9000;
        transition: all .5s ease-out;
        @media screen and (max-width: $break-small) {
        }
    }

    .nav-row{
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        background-color: rgba(0,0,0,0);
    }

    .nav-shadow{
        width: 100%;
        height: 98px;
        position: fixed;
        z-index: -1;
    }

    .nav-wrapper .row{
        margin-bottom: 15px;
        pointer-events:auto;
        @media screen and (max-width: $break-small) {
            margin-bottom: 5px;
        }
    }

    .nav-menu-default{
        padding-right: 20px;
    }

    .nav-main-menu{
        text-align: left;
    }

    .nav-first-letter{
        font-size: 24pt;
        @media screen and (max-width: $break-large) {
            font-size: 20pt;
        }
        @media screen and (max-width: $break-medium) {
            font-size: 16pt;
        }
    }

    .nav-header-text{
        font-size: 20pt;
        font-weight: 500;
        color: white;
        padding: 4px 10px 5px 10px;
        margin-right: 20px;
        text-decoration: none;
        letter-spacing: 1px;
        word-spacing: 5px;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        @media screen and (max-width: $break-large) {
            padding: 8px 10px 5px 10px;
            font-size: 16pt;
        }
        @media screen and (max-width: $break-medium) {
            font-size: 12pt;
            padding: 10px 10px 5px 10px;
            margin-right: 0px;
        }
    }

    .nav-header-text:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }

    .nav-header-text:hover:before{
        visibility: visible;
        transform: scaleX(.9);
    }

    .nav-header-link{
        text-decoration:none;
        cursor: pointer;
        letter-spacing: 1px;
    }

    .nav-home-button{
        border-radius: 15px;
        padding-bottom: 5px;
        height: 100%;
        text-transform: none;
        display: inline-block;
        vertical-align: top;
        border-color: darkslategray;
        background-color: teal;
        border-style: solid;
        border-width: 2px;
    }

    .nav-logo{
        display:inline-block;
        padding-right: 5%;
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .nav-logo-mobile{
        display: none;
        @media screen and (max-width: $break-small) {
            display: inline-block;
        }
    }

    .nav-item{
        vertical-align: top;
        text-align: center;
        font-weight: bold;
        font-size: 14pt;
        padding-left: 10%;
        padding-top:12px;
        display: inline-block;
        transition: padding-right .2s;
        // &:hover{
        //     padding-right: 3%;
        //     transition: padding-right .2s;
        //     @media screen and (max-width: $break-small) {
        //         padding-right: 5%;
        //     }
        // }
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .nav-item-text{
        color:white;
        font-weight: 300;
        position: relative;
        margin-left: 15px;
        margin-right: 0px;
        top:-10px;
        display: inline-block;
        transition: all .2s;
        opacity: 0;
        max-width: 0px;
        white-space: nowrap;
        @media screen and (max-width: $break-small) {
            opacity: 1;
        }
    }

    .nav-extender{
        height: 200px;
        width: 100%;
        position: fixed;
    }

    .nav-item-tooltip{
        pointer-events: none;
        visibility: hidden;
        width: 100px;
        background-color: black;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        right: -100%;
        top: 70px;
        
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 999;
        color:white;
        font-weight: 300;
        transition: all .2s;
        opacity: 1;
        // &:after{
        //     content: " ";
        //     position: absolute;
        //     bottom: 100%;  /* At the top of the tooltip */
        //     left: 50%;
        //     margin-left: -5px;
        //     border-width: 5px;
        //     border-style: solid;
        //     border-color: transparent transparent black transparent;
        // }
        @media screen and (max-width: $break-small) {
            opacity: 1;
        }
    }

    .nav-item-tooltip::after{
        content: " ";
        position: absolute;
        bottom: 100%;  /* At the top of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent black transparent;
    }

    .nav-item-link{
        cursor: pointer;
        color:white;
        font-weight: 100;
        position: relative;
        display: inline-block;
        &:hover{
            .nav-item-tooltip{
                visibility: visible;
            }
        }
        // &:hover{
        //     .nav-item-text{
        //         opacity: 1;
        //         transition: all .8s;
        //         max-width: 200px;
        //     }
        // }
        @media screen and (max-width: $break-small) {
            margin-left: -45px;
        }
    }

    .nav-item-link:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        visibility: hidden;
        transform: scaleX(0);
        transform-origin: left;
        transition: all 0.3s ease-in-out;
    }

    .nav-item-link:hover:before{
        visibility: visible;
        transform: scaleX(1);
        @media screen and (max-width: $break-small) {
            visibility: hidden;
        }
    }

    #nav-image{
        height: 35px;
        display: inline-block;
        @media screen and (max-width: $break-medium) {
            margin-top: 5px;
            height: 30px;
        }
    }

// Nav Mobile Menu

    .nav-mobile-menu{
        display: none;
        text-align: right;
        @media screen and (max-width: $break-small) {
            display: inline-block;
        }
    }

    .nav-menu{
        text-align: right;
        padding-right: 100px;
    }

    .mobile-menu-popout{
        position: fixed;
        width: 60%;
        background-color: black;
        z-index: 900;
        left: 100%;
        transition: all .5s ease-out;
        border-bottom-left-radius: 25px;
        border-left: solid gray 2px;
        border-bottom: solid gray 2px;
    }

    .nav-item-mobile{
        border-top: solid gray 2px;
        padding: 20px 0px;
        text-align: center;
    }

    .nav-item-spacer{
        height: 62px;
    }

/* Home Page Styling */

    .spacer{
        height: 150px;
    }
    
    .logo-space{
        height: 180px;
        width: 300px;
        padding-top: 0px;
        text-align: center;
        position: absolute;
        margin-top: -50px;
        &:hover{
            #logo{
                transition: all 1s;
                transform: scale(1.1, 1.1);
                opacity: 1;
                -webkit-filter: drop-shadow(0px 0px 50px rgba(0, 225, 255, 7));
                filter: drop-shadow(0px 0px 50px rgba(0, 225, 255, .7));
            }
        }
        @media screen and (max-width: $break-small) {
            width: 100%;
        }
    }

    #logo{
        cursor: pointer;
        height: 200px;
        position: relative;
        opacity: 1;
        transition: all 1s;
        -webkit-filter: drop-shadow(0px 0px 0px rgba(0, 225, 255, 0));
        filter: drop-shadow(0px 0px 0px rgba(0, 225, 255, 0));
        &:hover{
            transition: all 1s;
            transform: scale(1.1, 1.1);
            opacity: 1;
            -webkit-filter: drop-shadow(0px 0px 50px rgba(0, 225, 255, 7));
            filter: drop-shadow(0px 0px 50px rgba(0, 225, 255, .7));
        }
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    #logo-mobile{
        cursor: pointer;
        width: 33vw;
        height: auto;
        position: relative;
        text-align: center;
        display: none;
        opacity: 1;
        transition: all 1s;
        -webkit-filter: drop-shadow(0px 0px 0px rgba(0, 225, 255, 0));
        filter: drop-shadow(0px 0px 0px rgba(0, 225, 255, 0));
        &:hover{
            transition: all 1s;
            transform: scale(1.1, 1.1);
            opacity: 1;
            -webkit-filter: drop-shadow(0px 0px 50px rgba(0, 225, 255, 7));
            filter: drop-shadow(0px 0px 50px rgba(0, 225, 255, .7));
        }
        @media screen and (max-width: $break-small) {
            display: inline-block;
        }
    }

    .splash-first-letter{
        font-size: 8vw;
    }

    .splash-name-display{
        position: relative;
        color: white;
        display: none;
        text-align: center;
        font-size: 1rem;
        font-size: 6vw;
        padding-top: 20px;
        font-weight: 500;
        text-decoration: none;
        letter-spacing: 1px;
        word-spacing: 3px;
        font-family: 'Montserrat', sans-serif;
        margin:auto;
        @media screen and (max-width: $break-small) {
            width: 80%;
            display: inline-block;
        }
    }

    // #read-more{
    //     height: 25px;
    //     opacity: .1;
    //     transition: all 1s;
    //     display: block;
    //     pointer-events:none;
    //     margin:auto;
    //     margin-top: 60px;
    // }

    #read-more{
        height: 25px;
        opacity: .3;
        transition: all 1s;
        display: inline-block;
        pointer-events:none;
        margin: auto;
        margin-right: 20px;
        @media screen and (max-width: $break-small) {

        }
    }

    .homescreen-banner{
        height: 100vh;
        background: url(../images/glowing-background.jpg) no-repeat center center fixed;
        background-size: cover;
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
        margin-top: -100px;
        transition: opacity .1s;
        z-index: 0;
        opacity: 1;
        box-sizing: border-box; 
    }

    .splash-screen-banner{
        min-height: 100vh;
        // min-height: -webkit-fill-available;
        width: 100vw;
        /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.582) 0%, rgb(255, 255, 255) 2%, rgb(255, 255, 255) 100%); */
        margin:auto;
        text-align: center;
        // vertical-align: middle;
        // line-height: calc(100vh - 71px);
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        box-sizing: border-box;
        @media screen and (max-width: $break-small) {
            // height: calc(100vh - 57px);
        }
    }

    .scroll-indicator{
        color: white;
        position: absolute;
        cursor: pointer;
        bottom: 4%;
        right: 5%;
        font-size: 14pt;
        padding: 20px 25px;
        display: inline-block;
        // white-space:nowrap;
        width: 350px;
        opacity: .5;
        &:hover{
            opacity: 1;
            transition: all .8s;
            #read-more{
                opacity: .8;
                transition: all .8s;
                -webkit-filter: drop-shadow(0px 0px 30px rgba(0, 225, 255, 1));
                filter: drop-shadow(0px 0px 30px rgba(0, 225, 255, 1));
            }
        }
        @media screen and (max-width: $break-small) {
            bottom: 2%;
            padding: 10px 25px;
            text-align: center;
            font-size: 1rem;
        }
    }

    .scroll-text{
        display: inline-block;
        white-space: nowrap;
        transform: translateY(-6px);
    }

// About Me Styling

    .about-me-banner{
        // height: calc(100vh - 71px);
        max-height: 600px;
        width: 100vw;
        /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.582) 0%, rgb(255, 255, 255) 2%, rgb(255, 255, 255) 100%); */
        margin:auto;
        text-align: center;
        background: white;
        box-sizing: border-box;
        @media screen and (max-width: $break-medium) {
            max-height: 2000px;
            text-align: center;
            padding-bottom: 50px;
        }
    }

    .about-row{
        height: inherit;
        display: flex;
        flex-wrap:wrap;
        margin-bottom:0px;
    }
    .about-col-left{
        height: inherit;
        text-align: right;
        vertical-align: middle;
        line-height: 600px;
        width: 25vw;
        @media screen and (max-width: $break-medium) {
            height: auto;
            vertical-align: auto;
            line-height: normal;
            width: 100vw;
            text-align: center;
            padding: 50px 0px;
        }
    }
    .about-col-right{
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap:wrap;
        width: 75vw;
        @media screen and (max-width: $break-medium) {
            width: 100vw;
        }
    }

    .about-info-container{
        margin-top: -5%;
        width: 90%;
        text-align: center;
    }

    .avatar{
        height: 300px;
        border-radius:50%;
        box-shadow: rgba(0,0,0,0.8) -5px 10px 20px;
        vertical-align: middle;
    }

    .about-me-title{
        display: block;
        min-width: 100%;
        font-weight: 600;
    }

    .about-me-info{
        font-size: 14pt;
        font-weight: 300;
        width: 80%;
        margin:auto;
        margin-top: 30px;
        text-align: left;
        @media screen and (max-width: $break-medium) {
            font-size: 12pt;
        }
    }

    .about-me-highlight{
        color: red;
        font-weight: 500;
        &:hover{
            text-decoration: underline;
            transition: all .5s;
        }
    }

// Skills Styling

    .skills-banner-container{
        display: grid;
        grid-template-columns: 1fr;
    }
    
    .skills-banner-container div {
        grid-row-start: 1;
        grid-column-start: 1;
    }

    .skills-banner{
        /* background: linear-gradient(180deg, rgba(0,119,190,1) 0%, rgba(0,119,190,1) 85%, rgba(0,45,71,1) 100%); */
        background: rgb(20, 140, 200);
        min-height: 1400px;
        width: 100vw;
        max-width: 100vw;
        align-items: left;
        z-index: 100;
        padding-top: 0px;
        transition: all .3s ease-out;
        transform: translateZ(0);
        transform-origin: left;
        box-sizing: border-box; 
        @media screen and (max-width: $break-small) {
            min-height: 200px;
            height: 200px;
        }
    }
    // .skills-banner-text{
    //     text-align: center;
    //     margin-top: 50px;
    //     opacity: 1;
    //     vertical-align:middle;
    //     /* transition: font-size 1s ease .2s, opacity 1s ease .2s, line-height 1s; */
    //     transition: all 1s ease .2s;
    //     transform: translateZ(0);
    //     font-weight: bolder;
    //     font-size: 54pt;
    //     font-family: Azonix;
    // }

    .skills-bubble-container{
        height:300px;
        width: 300px;
        text-align: center;
        display: inline-block;
        position: absolute;
        transition: all .3s;
        margin: auto;
        left: 0;
        right: 0;
        margin-top: 100px;
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }
    .skills-bubble-spacer{
        height:300px;
        width: 300px;
        text-align: center;
        display: inline-block;
        margin: auto;
        left: 0;
        right: 0;
        margin-top: 100px;
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }
    .skills-bubble-container-1{
        height:100px;
        width: 300px;
        text-align: right;
        margin:auto;
        margin-top: 20px;
        padding-right: 60px;
        animation: movingUp 4s infinite alternate ease-in-out;
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }
    .skills-bubble-container-2{
        height:75px;
        width: 300px;
        text-align: left;
        padding-left: 80px;
        margin:auto;
        margin-top:-10px;
        padding-top: 10px;
        animation: movingUp 3s infinite alternate ease-in-out;
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .skills-bubble-container-3{
        height: 100px;
        width: 300px;
        text-align: left;
        padding-left: 150px;
        margin:auto;
        margin-top:-5px;
        animation: movingUp 2s infinite alternate ease-in-out;
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .skills-bubble{
        display: inline-block;
        width: 100%;
        height: 100%;
        max-height: 300px;
        max-width: 300px;
        margin-top: 25%;
        opacity: 0;
        transition: margin-top, opacity 1.5s;
        transform: translateZ(0);
        border-style: solid;
        border-width: 2px;
        border-color: black;
        border-radius: 200px;
        background-color: white;
        text-align: center;
        box-shadow: rgba(0,0,0,0.8) -10px 15px 20px;
        background-image: radial-gradient(farthest-corner at 230px 0px,
      rgb(255, 255, 255) 0%, transparent 10%, rgb(100, 203, 255) 40%,  rgb(100, 203, 255) 70%, rgb(255, 255, 255) 90%);
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .skills-bubble-1{
        display: inline-block;
        width: 100%;
        height: 100%;
        max-height: 100px;
        max-width: 100px;
        margin-top: 25%;
        margin-right: -12px;
        opacity: 0;
        transition: all 1.5s;
        transform: translateZ(0);
        border-style: solid;
        border-width: 2px;
        border-color: black;
        border-radius: 200px;
        background-color: white;
        text-align: center;
        box-shadow: rgba(0,0,0,0.8) -10px 15px 20px;
        animation: movingSideRight 6s infinite alternate ease-in-out;
        background-image: radial-gradient(farthest-corner at 230px 0px,
      rgb(255, 255, 255) 0%, transparent 10%, rgb(100, 203, 255) 40%,  rgb(100, 203, 255) 70%, rgb(255, 255, 255) 90%);
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .skills-bubble-2{
        display: inline-block;
        width: 100%;
        height: 100%;
        max-height: 50px;
        max-width: 50px;
        margin-top: 25%;
        opacity: 0;
        transition: opacity 1.5s, height 1.5s, width 1.5s, margin-top 1.5s;
        transform: translateZ(0);
        border-style: solid;
        border-width: 2px;
        border-color: black;
        border-radius: 200px;
        background-color: white;
        text-align: center;
        animation: movingSide 5s infinite alternate ease-in-out;
        box-shadow: rgba(0,0,0,0.8) -10px 15px 20px;
        background-image: radial-gradient(farthest-corner at 230px 0px,
      rgb(255, 255, 255) 0%, transparent 10%, rgb(100, 203, 255) 40%,  rgb(100, 203, 255) 70%, rgb(255, 255, 255) 90%);
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .skills-bubble-3{
        display: inline-block;
        width: 100%;
        height: 100%;
        max-height: 25px;
        max-width: 25px;
        margin-top: 25%;
        opacity: 0;
        transition: opacity 1.5s, height 1.5s, width 1.5s, margin-top 1.5s;
        transform: translateZ(0);
        border-style: solid;
        border-width: 2px;
        border-color: black;
        border-radius: 200px;
        background-color: white;
        text-align: center;
        animation: movingSide 4s infinite alternate ease-in-out;
        box-shadow: rgba(0,0,0,0.8) -10px 15px 20px;
        background-image: radial-gradient(farthest-corner at 230px 0px,
      rgb(255, 255, 255) 0%, transparent 10%, rgb(100, 203, 255) 40%,  rgb(100, 203, 255) 70%, rgb(255, 255, 255) 90%);
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .skills-bubble-text-container{
        margin-left: -12px;
        margin-top: 200px;
        left: 0;
        right: 0;
        display: inline-block;
        transition: transform .1s;
        transform: translateZ(0);
        position: absolute;
        animation: movingSide 4.5s infinite alternate ease-in-out;
        animation-play-state: paused;
        @media screen and (max-width: $break-small) {
            margin-top: 50px;
        }
    }

    .skills-first-letter{
        font-size: 42pt;
        font-weight: 600;
        @media screen and (max-width: $break-small) {
            font-size: 32pt;
        }
    }

    .skills-bubble-text{
        text-align: center;
        margin-top: -10px;
        opacity: 1;
        vertical-align:middle;
        line-height: 100px;
        font-weight: 600;
        transition: transform .1s;
        transform: translateZ(0);
        font-size: 36pt;
        /* font-family: Azonix; */
        font-family: 'Montserrat', sans-serif;
        animation: movingUp 2s infinite alternate ease-in-out;
        animation-play-state: paused;
        @media screen and (max-width: $break-small) {
            font-size: 24pt;
        }
    }

    @keyframes movingUp
    {
    /* modified */
        to {margin-top:10px;}
    }

    @keyframes movingSide
    {
    /* modified */
        to {margin-left:12px;}
    }

    @keyframes movingSideRight
    {
    /* modified */
        to {margin-right:12px;}
    }

// Skills Outlet Styling

    .skills-outlet{
        /* background-color: rgb(87, 99, 110); */
        background-color: rgba(45, 60, 77, 0.75);
        min-height: 1400px;
        width: 60vw;
        max-width: 60vw;
        margin-left: 40%;
        right: -15px;
        z-index: 9;
        text-align: center;
        // transition: all 1s ease-out;
        // transform: translateZ(0);
        padding-top: 50px;
        color: rgb(230, 230, 230);
        // color: rgb(162, 224, 255);
        // color: rgb(136, 195, 224);
        font-size: 22pt;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        box-sizing: border-box;
        @media screen and (max-width: $break-small) {
            padding-top: 230px;
            padding-bottom: 50px;
            width: 100vw;
            max-width: 100vw;
            margin-left: 0;
            right: 0px;
        }
    }

    .skill-level {
        font-size: .8em;
        z-index: 1;
        line-height: 1;
        width: 830px;
        transition: all 1s;
        height: 60px;
        margin-left: 130px;
        margin-right: 20px;
        padding-top:8px;
        padding-bottom:8px;
        background-image: repeating-linear-gradient(
        to right,
        #aaa,
        #aaa 1.1px,
        transparent 1px,
        transparent 9.98%
        );
        @media screen and (max-width: $break-xlarge) {
            max-width: 625px;
        }
        @media screen and (max-width: $break-large) {
            height: 50px;
            max-width: 450px;
        }
        @media screen and (max-width: $break-medium) {
            max-width: 250px;
            height: 40px;
            background-image: repeating-linear-gradient(
                to right,
                #aaa,
                #aaa 1.1px,
                transparent 1px,
                transparent 19.9%
                );
        }
        
        &:after {
            content: "";
            display: block;
            background-image: linear-gradient(to right,rgb(114, 159, 184) ,rgb(169, 226, 255));
            width: 50px;
            margin-bottom: 10px;
            height: 90%;
            position: relative;
            transition: all 1s;
            top: 50%;
            transform: translateY(-50%);
            transition: background-color .3s ease;
            cursor: pointer;
        }
        }
        
        @for $i from 1 through 100 {
        .percentage-#{$i} {
            &:after {
            $value: ($i * 1%);
            transition: all 1s;
            width: $value;
            }
        }

        .skill-level-holder{
            transition: all 1s;
            &:after {width: 1px !important;}
        }
    }

    dl {
        display: inline-block;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        position: relative;
        margin-left: 5%;
        padding: 20px;
        padding-top: 0px;
        @media screen and (max-width: $break-small) {
            padding-left: 0px;
            left: -30px;
        }
    }
    
    .skill-level-text {
        font-weight: 200;
        font-size: 12pt;
        display: flex;
        vertical-align: middle;
        line-height: 60px;
        height: 60px;
        width: 130px;
        position: absolute;
        left: 0;
        justify-content: flex-end;
        @media screen and (max-width: $break-small) {
            line-height: 30px;
            height: 30px;
            margin-left: -20px;
            width: 130px;
        }
    }

    .skills-level-header{
        font-weight: 500;
    }

    .skill-level-numbers{
        font-size: .8em;
        line-height: .75;
        width: 820px;
        transition: all 1s;
        height: 40px;
        margin-left: 50px;
        margin-top: 10px;
        padding-top:8px;
        padding-bottom:8px;
        & tr{
            border-bottom: none;
        }
        & th{
            font-size: 12pt;
            font-weight: 200;
            width:50%;
        }
        @media screen and (max-width: $break-xlarge) {
            max-width: 700px;
        }
        @media screen and (max-width: $break-large) {
            max-width: 525px;
        }
        @media screen and (max-width: $break-medium) {
            max-width: 320px;
        }
    }

    .skills-other{
        font-size: 12pt;
        font-weight: 300;
        text-align: left;
        margin-left: 10%;
        margin-bottom: 30px;
        margin-top: 60px;
        margin-right:5%;
    }

    .skills-other-list{
        font-size: 12pt;
        font-weight: 300;
        text-align: left;
        margin-left: 15%;
        margin-right:15%;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 150px auto;
        @media screen and (max-width: $break-small) {
            grid-template-columns: auto;
            text-align: center;
        }
    }

    .skills-other-list .other-title{
        grid-row-start: 1;
        grid-column-start: 1;
        @media screen and (max-width: $break-small) {
            margin-bottom: 20px;
        }
    }
    .skills-other-list .other-items{
        grid-row-start: 1;
        grid-column-start: 2;
        @media screen and (max-width: $break-small) {
            grid-row-start: 2;
            grid-column-start: 1;
        }
    }

    .other-title{
        font-size: 12pt;
        font-weight: 500;
        color:rgb(162, 224, 255);
        // padding-right: 10px;
    }

    .skills-highlight{
        color: rgb(162, 224, 255);
    }


    .home-container{
        width: 70%;
    }

    .home-text{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 3%;
        padding-bottom: 3%;
        margin-top: 30px;
        border-radius: 20px;
        border-color: black;
        border-style: solid;
        box-shadow: 15px 15px 20px grey;
        font-size: 48pt;
        font-weight: bold;
    }

    .home-flavor-text{
        font-size: 24pt;
    }

    .parallax-div{
        display: inline-block;
        position: absolute;
        right: 100%;
        transition: opacity 1s, right 1s;
        opacity: 0;
    }

    .parallax-left{
        display: inline-block;
        position: absolute;
        right: 10%;
        transition: opacity 1s, right 1s;
        opacity: 0;
    }

    .parallax-div-right{
        display: inline-block;
        position: absolute;
        left:100%;
        transition: opacity 1s, left 1s;
        transform: translateZ(0);
        opacity: 0;
    }

    .pretend-banner{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 3%;
        padding-bottom: 3%;
        margin-top: 30px;
        border-radius: 20px;
        border-color: black;
        border-style: solid;
        box-shadow: 15px 15px 20px grey;
        font-size: 48pt;
        font-weight:bold;
        height: 1000px;
        min-width: 500px;
        width: 70%;
        background-color: white;
    }

// Projects Styling

    .projects-banner-container{
        display: grid;
        grid-template-columns: 1fr;
    }

    .projects-banner-container div{
        grid-row-start: 1;
        grid-column-start: 1;
    }

    .projects-banner{
        /* background: linear-gradient(180deg, rgba(0,119,190,1) 0%, rgba(0,119,190,1) 85%, rgba(0,45,71,1) 100%); */
        background: #67aca1;
        height: 1000px;
        width: 100vw;
        max-width: 100vw;
        align-items: left;
        z-index: 100;
        padding-top: 0px;
        transition: all .3s ease-out;
        transform: translateZ(0);
        transform-origin: top;
        box-sizing: border-box; 
        @media screen and (max-width: $break-small) {
            min-height: 200px;
            height: 200px;
        }
    }

    .projects-first-letter{
        font-size: 42pt;
        font-weight: 600;
        @media screen and (max-width: $break-small) {
            font-size: 32pt;
        }
    }

    .projects-banner-text-container{
        height:900px;
        z-index: 101;
    }

    .projects-banner-text{
        text-align: center;
        margin-top: -10px;
        opacity: 1;
        vertical-align:middle;
        line-height: 600px;
        font-weight: 600;
        transition: all .3s ease;
        transform: translateZ(0);
        font-size: 36pt;
        /* font-family: Azonix; */
        font-family: 'Montserrat', sans-serif;
        @media screen and (max-width: $break-small) {
            line-height: 200px;
            font-size: 24pt;
        }
    }

// Projects Outlet Styling

    .projects-outlet{
        /* background-color: rgb(87, 99, 110); */
        background-color: rgba(45, 60, 77, 0.75);
        min-height: 1400px;
        width: 100vw;
        max-width: 100vw;
        margin-top:150px;
        padding-bottom: 100px;
        z-index: 9;
        text-align: center;
        // transition: all 1s ease-out;
        // transform: translateZ(0);
        padding-top: 100px;
        color: rgb(230, 230, 230);
        // color: rgb(162, 224, 255);
        // color: rgb(136, 195, 224);
        font-size: 14pt;
        font-weight: 300;
        font-family: 'Montserrat', sans-serif;
        box-sizing: border-box;
    }

    .projects-outlet-container{
        max-width: 2200px;
        width: 100%;
        margin:auto;
    }

    .projects-all-container{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-left: 5%;
        margin-right: 5%;
    }

    .project-ind-container{
        flex: 1 0 33%;
        justify-content: space-around;
        max-width: 500px;
        display: inline-block;
        text-align: center;
        padding: 20px;
        opacity: 1;
        transition: opacity .5s ease;
    }

    .project{
        width: 400px;
        display: inline-block;
        text-align: center;
        padding: 20px;
        opacity: 1;
        transition: opacity .5s ease;
    }

    .project-holder{
        opacity: 0 !important;
        transition: opacity .5s ease;
    }

    .project-image{
        height: 400px;
        width: 400px;
        border-style: solid;
        border-width: 3px;
        border-color: black;
        border-radius: 5px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
        box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.63);
        @media screen and (max-width: $break-small) {
            height: 300px;
            width: 300px;
            margin-bottom: 20px;
        }
    }

    .project-title{
        font-weight: 500;
        margin: 20px 0px;
    }

    .project-tools{
        margin: 10px 0px;
        font-size: 16pt;
        font-weight: 500;
        height: 104px;
        display: grid;
        grid-template-columns: 95px auto;
        @media screen and (max-width: $break-medium) {
            grid-template-columns: auto;
            font-size: 14pt;
            height: auto;
        }
    }

    .project-tools .project-tools-highlight{
        font-weight: 600;
        grid-row-start: 1;
        grid-column-start: 1;
        padding-top: 8px;
        @media screen and (max-width: $break-medium) {
            grid-row-start: 1;
            grid-column-start: 1;
            margin-bottom: 10px;
            padding-top: 0px;
        }
    }

    .project-tools .project-tools-list{
        font-weight: 600;
        grid-row-start: 1;
        grid-column-start: 2;
        @media screen and (max-width: $break-medium) {
            grid-row-start: 2;
            grid-column-start: 1;
        }
    }

    .project-description{
        margin: 20px 0px;
        min-height: 140px;
        @media screen and (max-width: $break-medium) {
            min-height: 0px;
        }
        @media screen and (max-width: $break-small) {
            font-size: 12pt;
        }
    }

    .project-links{
        display: flex;
        width: 300px;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 10px;
        justify-content: space-around;
        & a{
            font-size: 15pt;
            font-weight: 600;
        }
    }

    .project-button{
        cursor: default;
        color: white;
        border: solid 1.5px black;
        font-size: 14pt;
        font-weight: 500;
        border-radius: 50px;
        display:inline-block;
        padding: 7px 15px;
        padding-top: 9px;
        margin: 5px;
        margin-top: 0px;
        @media screen and (max-width: $break-small) {
            font-size: 12pt;
        }
    }

    .pb-react{
        background-color: #4fb2ce;
        &:hover{
            background-color: #58c5e4;
        }
    }

    .pb-html{
        background-color: #F36527;
        &:hover{
            background-color: #f08051;
        }
    }

    .pb-css{
        background-color: #0170BC;
        &:hover{
            background-color: #0083da;
        }
    }

    .pb-blender{
        background-color: #E87D0D;
        &:hover{
            background-color: #ff9625;
        }
    }

    .pb-audacity{
        background-color: #0000CB;
        &:hover{
            background-color: #1111f6;
        }
    }

    .pb-adobe{
        background-color: #F561FF;
        &:hover{
            background-color: #f788ff;
        }
    }

    .pb-js{
        background-color: #e0c91c;
        &:hover{
            background-color: #f8e33f;
        }
    }

    .pb-fb{
        background-color: #FFA713;
        &:hover{
            background-color: #fdbe51;
        }
    }

    .pb-unity{
        background-color: rgb(136, 135, 135);
        &:hover{
            background-color: rgb(168, 166, 166);
        }
    }

    .pb-cSharp{
        background-color: #A178DB;
        &:hover{
            background-color: #b788f8;
        }
    }

    .hr-projects{
        border-color: #aaa;
        box-shadow: 0px 0px 20px #fff;
        width: 100%;
        display: none;
        @media screen and (max-width: $break-small) {
            display: inline-block;
        }
    }

    .projects-banner-end{
        /* background: linear-gradient(180deg, rgba(0,119,190,1) 0%, rgba(0,119,190,1) 85%, rgba(0,45,71,1) 100%); */
        background: #67aca1;
        height: 20px;
        width: 100vw;
        max-width: 100vw;
        align-items: left;
        z-index: 100;
        padding-top: 0px;
        transition: all .3s ease-out;
        transform: translateZ(0);
        transform-origin: top;
        box-sizing: border-box; 
        @media screen and (max-width: $break-small) {
            min-height: 20px;
            height: 20px;
        }
    }

// Contact Styling

    .contact-banner-container{
        display: grid;
        grid-template-columns: 1fr;
    }

    .contact-banner-container .contact-banner{
        z-index: 11;
        background: #fa6161;
        transition: all .3s ease-out;
        grid-row-start: 1;
        grid-column-start: 1;
        transform-origin: top right;
        @media screen and (max-width: $break-medium) {
            min-height: 200px;
            height: 200px;
        }
    }
    .contact-banner-container .contact-text-container{
        grid-row-start: 1;
        grid-column-start: 1;
    }
    .contact-banner-container .contact-outlet{
        grid-row-start: 1;
        grid-column-start: 1;
    }

    .contact-text-container{
        pointer-events: none;
        height: 1000px;
        width: 100vw;
        max-width: 100vw;
        align-items: left;
        z-index: 100;
        padding-top: 0px;
        transition: all .3s ease-out;
        transform: translateZ(0);
        transform-origin: top;
        box-sizing: border-box; 
        @media screen and (max-width: $break-medium) {
            min-height: 200px;
            height: 200px;
        }
    }

    .contact-banner-text{
        // pointer-events: auto;
        text-align: center;
        margin-top: -10px;
        opacity: 1;
        vertical-align:middle;
        line-height: 600px;
        font-weight: 600;
        transition: all .3s ease;
        transform: translateZ(0);
        font-size: 36pt;
        /* font-family: Azonix; */
        font-family: 'Montserrat', sans-serif;
        @media screen and (max-width: $break-medium) {
            line-height: 200px;
        }
        @media screen and (max-width: $break-small) {
            font-size: 24pt;
        }
    }

    .contact-first-letter{
        font-size: 42pt;
        font-weight: 600;
        @media screen and (max-width: $break-small) {
            font-size: 32pt;
        }
    }

    .contact-outlet{
        /* background-color: rgb(87, 99, 110); */
        background-color: rgba(45, 60, 77, 0.75);
        min-height: 700px;
        width: 100vw;
        max-width: 100vw;
        padding-bottom: 100px;
        z-index: 9;
        text-align: center;
        // transition: all 1s ease-out;
        // transform: translateZ(0);
        padding-top: 100px;
        color: rgb(230, 230, 230);
        // color: rgb(162, 224, 255);
        // color: rgb(136, 195, 224);
        font-size: 14pt;
        font-weight: 300;
        font-family: 'Montserrat', sans-serif;
        box-sizing: border-box;
    }

    .contact-outlet-container{
        color: black;
        max-width: 2200px;
        height: 600px;
        background-color: #aaa;
        margin-top: 50px;
        margin-left: 15%;
        padding-top: 10px;
        margin-right: calc(40% + 80px);
        width: auto;
        box-shadow: rgba(0,0,0,0.8) -10px 15px 20px;
        transform: rotate(-1deg);
        position: relative;
        z-index: 999;
        max-width: 1200px;
        min-width: 800px;
        @media screen and (max-width: $break-large) {
            width: 500px;
            min-width: 0px;
        }
        @media screen and (max-width: $break-medium) {
            margin: auto;
            margin-top: 180px;
        }
        @media screen and (max-width: $break-small) {
            width: 300px;
            height: 500px;
            min-width: 0px;
        }
    }

    .contact-outlet-container,
    .contact-outlet-container::before,
    .contact-outlet-container::after {
    /* Styles to distinguish sheets from one another */
    box-shadow: 1px 1px 1px rgba(0,0,0,0.25);
    border: 1px solid #bbb;
    }

    .contact-outlet-container::before{
        content: "";
        position: absolute;
        height: 95%;
        width: 99%;
        background-color: #fff;
        box-shadow: rgba(0,0,0,0.8) 5px 5px 30px 10px;
        @media screen and (max-width: $break-large) {
            width: 97%;
        }
    }
    .contact-outlet-container::after {
        content: "";
        position: absolute;
        height: 95%;
        width: 99%;
        background-color: #ddd;
    }

    .contact-outlet-container::before {
        right: -10px;
        top: 10;
        transform: rotate(0deg);
        z-index: -1;
        @media screen and (max-width: $break-large) {
            right: 0px;
        }
    }

    .contact-outlet-container::after {
        top: 5px;
        right: -5px;
        transform: rotate(8deg);
        z-index: -2;
    }

    .contact-all-info{
        display: grid;
        grid-template-columns: 40% auto;
        height: 100%;
        margin-left: 18px;
        @media screen and (max-width: $break-large) {
            margin-left: 15px;
            grid-template-columns: auto;
            grid-template-rows: 30% auto;
        }
        @media screen and (max-width: $break-small) {
            grid-template-rows: 40% auto;
            height: 280px;
            margin-left: 8px;
            width: 290px;
        }
    }

    .contact-logo-container{
        vertical-align: middle;
        line-height: 600px;
        background-color: rgb(43, 58, 75);
        height: 94.8%;
        @media screen and (max-width: $break-large) {
            height: 100%;
            line-height: 275px;
        }
        @media screen and (max-width: $break-small) {
            line-height: 175px;
            width: 290px;
        }
    }

    .contact-logo{
        height: 100px;
        @media screen and (max-width: $break-small) {
            height: 70px;
        }
    }

    .contact-info-container{
        display: grid;
        grid-template-columns: 1fr;
        height: 600px;
        padding-top: 120px;
        margin-right: 40px;
        margin-left: 40px;
        padding-bottom: 120px;
        grid-template-rows: auto auto auto 100px;
        @media screen and (max-width: $break-large) {
            height: 420px;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @media screen and (max-width: $break-small) {
            margin-right: 20px;
            margin-left: 16px;
            padding-top: 0px;
            font-size: 12pt;
        }
    }

    .contact-info-container .contact-header{
        grid-row-start: 1;
        grid-column-start: 1;
    }
    .contact-info-container .contact-email{
        grid-row-start: 2;
        grid-column-start: 1;
    }
    .contact-info-container .contact-other{
        grid-row-start: 3;
        grid-column-start: 1;
    }
    .contact-info-container .contact-icon-list{
        grid-row-start: 4;
        grid-column-start: 1;
    }

    .contact-header{
        margin: 20px;
        margin-left: 10px;
    }

    .contact-email{
        margin: 20px;
        margin-left: 10px;
    }

    .contact-other{
        margin: 20px;
        margin-left: 10px;
    }

    .contact-icon-list{
        display: grid;
        margin:auto;
        margin-top: 20px;
        justify-items: center;
        width: 100%;
        grid-template-columns: auto auto auto auto;
    }

    .contact-icon-list .contact-icon{
        text-align: center;
        filter: invert(100%);
        height: 30px;

    }

    .footer{
        height: 345px;
        border-top: gray solid 2px;
        color: gray;
        font-size: 14pt;
        padding: 0px 10%;
        padding-top: 100px;
        @media screen and (max-width: $break-small) {
            height: 145px;
            font-size: 12pt;
            padding-top: 30px;
        }
    }